import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_van_floating_bubble = _resolveComponent("van-floating-bubble");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view), _createVNode(_component_van_floating_bubble, {
    icon: "wap-home-o",
    onClick: $options.onClick,
    axis: "xy",
    magnetic: "x",
    offset: {
      y: 40
    }
  }, null, 8, ["onClick"])]);
}